.vl_line > .not_selected {
  border-left: 2px solid grey;
  height: 15px;
  margin-top: 0.8vh;
}

.vl_line > .selected {
  border-left: 2px solid white;
  height: 15px;
  margin-top: 0.8vh;
}

.vl_line {
  min-width: 100%;
  max-width: 100%;
  min-height: 3vh;
  max-height: 3vh;
  justify-content: center;
  display: flex;
}

.ant-select-selection-item-remove {
  color: var(--text-main-color) !important;
}

.not_selected_dynamic {
  filter: grayscale(100%);
}

.upload-text-drop {
  color: var(--heading-color);
  font-size: 1.5vw;
}

.text_button_add_new_profile,
.tlp_values {
  font-size: 2vw;
}

.tlp {
  text-transform: uppercase;
  font-size: 2.5vw;
}

.analysis_type_radio {
  font-size: 2.5vw;
}

.analyses_options {
  font-size: 2.5vw;
}

@media screen and (max-width: 600px) {
  .upload-text-drop {
    color: var(--heading-color);
    font-size: 3vw;
  }

  .text_button_add_new_profile {
    font-size: 2.3vw;
  }

  .tlp {
    text-transform: uppercase;
    font-size: 2.5vw;
  }

  .analysis_type_radio {
    font-size: 2.5vw;
  }
}

@media only screen and (min-width: 637px) {
  .analyses_options {
    font-size: 1.4vw;
  }

  .tlp {
    text-transform: uppercase;
    font-size: 2vw;
  }

  .analysis_type_radio {
    font-size: 2vw;
  }
}

@media only screen and (min-width: 992px) {
  .title_option {
    font-size: 2vw;
  }

  .text_button_add_new_profile {
    font-size: 1vw;
  }

  .tlp {
    text-transform: uppercase;
    font-size: 1.3vw;
  }

  .analysis_type_radio {
    font-size: 1.3vw;
  }

  .analyses_options {
    font-size: 1vw;
  }
}

@media only screen and (min-width: 1200px) {
  .title_option {
    font-size: 1vw;
  }

  .text_button_add_new_profile {
    font-size: 1vw;
  }

  .tlp {
    text-transform: uppercase;
    font-size: 0.7vw;
  }

  .analysis_type_radio {
    font-size: 0.8vw;
  }

  .analyses_options {
    font-size: 0.8vw;
  }
}

.ant-form-item {
  margin: 0;
  width: 100%;
}

.formulario-height > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input {
  min-height: 0;
}
